import { createApp } from "vue"

import "@/assets/index.css"

import App from "@/App.vue"
import datadog from "@/plugins/datadog/datadog.ts"
import router from "@/plugins/router"
import { initWebchat } from "@/services/WebchatService"
import { VueQueryPlugin } from "@tanstack/vue-query"

const app = createApp(App)

app.provide("isStaging", __APP_IS_STAGING__)
app.use(VueQueryPlugin)
app.use(router)
app.use(datadog)
app.mount("#app")

initWebchat()
