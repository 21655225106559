import { datadogRum, RumEventDomainContext } from "@datadog/browser-rum"
import { App, ComponentPublicInstance } from "vue"

import {
  canInitializeDatadog,
  commonDatadogParams,
  isServerError,
  setRequestMetadata,
} from "./datadogHelper.ts"

export default {
  install(app: App) {
    if (canInitializeDatadog()) {
      datadogRum.init({
        ...commonDatadogParams,
        applicationId: import.meta.env.VITE_DATADOG_APPLICATION_ID,
        sessionReplaySampleRate: 20,
        trackUserInteractions: true,
        trackSessionAcrossSubdomains: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: "mask-user-input",
        allowedTracingUrls: [
          {
            match: /https:\/\/.*\.staging.soenergy\.co\/.*/,
            propagatorTypes: ["tracecontext"],
          },
          {
            match: /https:\/\/.*\.preview\.soenergy\.co\/.*/,
            propagatorTypes: ["tracecontext"],
          },
          {
            match: /https:\/\/.*\.so\.energy\/.*/,
            propagatorTypes: ["tracecontext"],
          },
        ],
        beforeSend: (event, context: RumEventDomainContext) => {
          const error = "error" in context ? context?.error : null
          if (isServerError(error)) {
            setRequestMetadata(event, error)
          }
          return true
        },
      })

      const originalErrorHandler = app.config.errorHandler
      app.config.errorHandler = function (
        error: unknown,
        vm: ComponentPublicInstance | null,
        info: string,
      ) {
        console.error(error) // Expose error in the console to facilitate debugging
        if (typeof originalErrorHandler === "function") {
          originalErrorHandler.call(this, error, vm, info)
        }
        const typedError = error as Error

        datadogRum.addError(typedError, {
          error: { kind: typedError.name, stack: typedError.stack },
          context: { info },
        })
      }
    }
  },
}
