<script setup lang="ts">
  import { cn } from "@/lib/utils"
  import {
    ProgressIndicator,
    ProgressRoot,
    type ProgressRootProps,
  } from "radix-vue"
  import { computed, type HTMLAttributes } from "vue"

  const props = withDefaults(
    defineProps<ProgressRootProps & { class?: HTMLAttributes["class"] }>(),
    {
      modelValue: 0,
    },
  )

  const delegatedProps = computed(() => {
    const { class: _, ...delegated } = props

    return delegated
  })
</script>

<template>
  <ProgressRoot
    v-bind="delegatedProps"
    :class="
      cn(
        'relative h-2 w-full overflow-hidden rounded-sm bg-secondary-300 dark:bg-secondary-500',
        props.class,
      )
    "
  >
    <ProgressIndicator
      class="h-full w-full flex-1 bg-primary-400 transition-all"
      :style="`transform: translateX(-${100 - (props.modelValue ?? 0)}%);`"
    />
  </ProgressRoot>
</template>
