import routesDefinition from "@/plugins/router/routes.ts"
import { createRouter, createWebHistory } from "vue-router"

import { beforeEachGuard } from "./routerGuards"

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: routesDefinition,
})

router.beforeEach(beforeEachGuard)

export default router
