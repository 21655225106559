/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Portal API Gateway
 * OpenAPI spec version: 2.0.0
 */

/**
 * Current account state for meter appointment
 */
export type MeterAppointmentState =
  (typeof MeterAppointmentState)[keyof typeof MeterAppointmentState]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MeterAppointmentState = {
  traditionalSmartEligible: "traditionalSmartEligible",
  appointmentBooked: "appointmentBooked",
  other: "other",
} as const
