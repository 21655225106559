export const getPublicWebsiteUrl = (): string => {
  const publicWebsiteUrl = import.meta.env.VITE_PUBLIC_WEBSITE_URL

  const currentUrl = window.location.href

  const isServedFromMonorepoUrl = currentUrl.startsWith(publicWebsiteUrl)
  if (isServedFromMonorepoUrl) {
    return "/"
  }
  // Either served directly under fe-nexus URL or in local development
  return `${publicWebsiteUrl}/`
}

export const getMyAccountUrl = (): string => {
  const currentUrl = window.location.href
  const isLegacyMyAccountPreviewUrl =
    /^https:\/\/fe-my-account(?:-.+)?\.preview\.soenergy\.co/.test(currentUrl)

  if (isLegacyMyAccountPreviewUrl) {
    return "/myaccount/"
  }

  return `${getPublicWebsiteUrl()}myaccount/`
}
