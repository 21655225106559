/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Portal API Gateway
 * OpenAPI spec version: 2.0.0
 */
import {
  useMutation,
  useQuery,
  type MutationFunction,
  type QueryFunction,
  type QueryKey,
  type UseMutationOptions,
  type UseMutationReturnType,
  type UseQueryOptions,
  type UseQueryReturnType,
} from "@tanstack/vue-query"
import { unref, type MaybeRef } from "vue"

import { customInstance, type ErrorType } from ".././client"
import type {
  Error,
  GetCustomerResponse,
  GetSmartMeterReadingFrequencyResponse,
  UpdateCustomerRequest,
  UpdateSmartMeterReadingFrequencyRequest,
} from ".././model"
import { useMutationOptions } from ".././mutationOptions"
import { useQueryOptions } from ".././queryOptions"

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1]

/**
 * @summary Get current customer information
 */
export const getCustomer = (
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<GetCustomerResponse>(
    { url: `/api/v1/customers/me`, method: "GET", signal },
    options,
  )
}

export const getGetCustomerQueryKey = () => {
  return ["api", "v1", "customers", "me"] as const
}

export const useGetCustomerQueryOptions = <
  TData = Awaited<ReturnType<typeof getCustomer>>,
  TError = ErrorType<Error>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getCustomer>>, TError, TData>
  >
  request?: SecondParameter<typeof customInstance>
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = getGetCustomerQueryKey()

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCustomer>>> = ({
    signal,
  }) => getCustomer(requestOptions, signal)

  const customOptions = useQueryOptions(
    { ...queryOptions, queryKey, queryFn },
    {},
  )

  return customOptions as UseQueryOptions<
    Awaited<ReturnType<typeof getCustomer>>,
    TError,
    TData
  >
}

export type GetCustomerQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCustomer>>
>
export type GetCustomerQueryError = ErrorType<Error>

/**
 * @summary Get current customer information
 */
export const useGetCustomer = <
  TData = Awaited<ReturnType<typeof getCustomer>>,
  TError = ErrorType<Error>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getCustomer>>, TError, TData>
  >
  request?: SecondParameter<typeof customInstance>
}): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetCustomerQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = unref(queryOptions).queryKey as QueryKey

  return query
}

/**
 * @summary Update customer information
 */
export const updateCustomer = (
  customerId: MaybeRef<number>,
  updateCustomerRequest: MaybeRef<UpdateCustomerRequest>,
  options?: SecondParameter<typeof customInstance>,
) => {
  customerId = unref(customerId)
  updateCustomerRequest = unref(updateCustomerRequest)

  return customInstance<GetCustomerResponse>(
    {
      url: `/api/v1/customers/${customerId}`,
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      data: updateCustomerRequest,
    },
    options,
  )
}

export const useUpdateCustomerMutationOptions = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateCustomer>>,
    TError,
    { customerId: number; data: UpdateCustomerRequest },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateCustomer>>,
  TError,
  { customerId: number; data: UpdateCustomerRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateCustomer>>,
    { customerId: number; data: UpdateCustomerRequest }
  > = (props) => {
    const { customerId, data } = props ?? {}

    return updateCustomer(customerId, data, requestOptions)
  }

  const customOptions = useMutationOptions({ ...mutationOptions, mutationFn })

  return customOptions
}

export type UpdateCustomerMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateCustomer>>
>
export type UpdateCustomerMutationBody = UpdateCustomerRequest
export type UpdateCustomerMutationError = ErrorType<Error>

/**
 * @summary Update customer information
 */
export const useUpdateCustomer = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateCustomer>>,
    TError,
    { customerId: number; data: UpdateCustomerRequest },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationReturnType<
  Awaited<ReturnType<typeof updateCustomer>>,
  TError,
  { customerId: number; data: UpdateCustomerRequest },
  TContext
> => {
  const mutationOptions = useUpdateCustomerMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * @summary Get smart meter reading frequency for customer
 */
export const getSmartMeterReadingFrequency = (
  customerId: MaybeRef<number>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  customerId = unref(customerId)

  return customInstance<GetSmartMeterReadingFrequencyResponse>(
    {
      url: `/api/v1/customers/${customerId}/smartMeterReadingFrequency`,
      method: "GET",
      signal,
    },
    options,
  )
}

export const getGetSmartMeterReadingFrequencyQueryKey = (
  customerId: MaybeRef<number>,
) => {
  return [
    "api",
    "v1",
    "customers",
    customerId,
    "smartMeterReadingFrequency",
  ] as const
}

export const useGetSmartMeterReadingFrequencyQueryOptions = <
  TData = Awaited<ReturnType<typeof getSmartMeterReadingFrequency>>,
  TError = ErrorType<Error>,
>(
  customerId: MaybeRef<number>,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getSmartMeterReadingFrequency>>,
        TError,
        TData
      >
    >
    request?: SecondParameter<typeof customInstance>
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = getGetSmartMeterReadingFrequencyQueryKey(customerId)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getSmartMeterReadingFrequency>>
  > = ({ signal }) =>
    getSmartMeterReadingFrequency(customerId, requestOptions, signal)

  const customOptions = useQueryOptions(
    { ...queryOptions, queryKey, queryFn },
    { customerId },
  )

  return customOptions as UseQueryOptions<
    Awaited<ReturnType<typeof getSmartMeterReadingFrequency>>,
    TError,
    TData
  >
}

export type GetSmartMeterReadingFrequencyQueryResult = NonNullable<
  Awaited<ReturnType<typeof getSmartMeterReadingFrequency>>
>
export type GetSmartMeterReadingFrequencyQueryError = ErrorType<Error>

/**
 * @summary Get smart meter reading frequency for customer
 */
export const useGetSmartMeterReadingFrequency = <
  TData = Awaited<ReturnType<typeof getSmartMeterReadingFrequency>>,
  TError = ErrorType<Error>,
>(
  customerId: MaybeRef<number>,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getSmartMeterReadingFrequency>>,
        TError,
        TData
      >
    >
    request?: SecondParameter<typeof customInstance>
  },
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetSmartMeterReadingFrequencyQueryOptions(
    customerId,
    options,
  )

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = unref(queryOptions).queryKey as QueryKey

  return query
}

/**
 * @summary Update smart meter reading frequency for customer
 */
export const updateSmartMeterReadingFrequency = (
  customerId: MaybeRef<number>,
  updateSmartMeterReadingFrequencyRequest: MaybeRef<UpdateSmartMeterReadingFrequencyRequest>,
  options?: SecondParameter<typeof customInstance>,
) => {
  customerId = unref(customerId)
  updateSmartMeterReadingFrequencyRequest = unref(
    updateSmartMeterReadingFrequencyRequest,
  )

  return customInstance<void>(
    {
      url: `/api/v1/customers/${customerId}/smartMeterReadingFrequency`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: updateSmartMeterReadingFrequencyRequest,
    },
    options,
  )
}

export const useUpdateSmartMeterReadingFrequencyMutationOptions = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateSmartMeterReadingFrequency>>,
    TError,
    { customerId: number; data: UpdateSmartMeterReadingFrequencyRequest },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateSmartMeterReadingFrequency>>,
  TError,
  { customerId: number; data: UpdateSmartMeterReadingFrequencyRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateSmartMeterReadingFrequency>>,
    { customerId: number; data: UpdateSmartMeterReadingFrequencyRequest }
  > = (props) => {
    const { customerId, data } = props ?? {}

    return updateSmartMeterReadingFrequency(customerId, data, requestOptions)
  }

  const customOptions = useMutationOptions({ ...mutationOptions, mutationFn })

  return customOptions
}

export type UpdateSmartMeterReadingFrequencyMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateSmartMeterReadingFrequency>>
>
export type UpdateSmartMeterReadingFrequencyMutationBody =
  UpdateSmartMeterReadingFrequencyRequest
export type UpdateSmartMeterReadingFrequencyMutationError = ErrorType<Error>

/**
 * @summary Update smart meter reading frequency for customer
 */
export const useUpdateSmartMeterReadingFrequency = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateSmartMeterReadingFrequency>>,
    TError,
    { customerId: number; data: UpdateSmartMeterReadingFrequencyRequest },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationReturnType<
  Awaited<ReturnType<typeof updateSmartMeterReadingFrequency>>,
  TError,
  { customerId: number; data: UpdateSmartMeterReadingFrequencyRequest },
  TContext
> => {
  const mutationOptions =
    useUpdateSmartMeterReadingFrequencyMutationOptions(options)

  return useMutation(mutationOptions)
}
