import { AuthenticationResponse } from "@/api/model/authenticationResponse"
import { useCookies } from "@vueuse/integrations/useCookies"
import { computed, ref } from "vue"

import { getApiRootDomain } from "./utils"

const AuthCookie = {
  longLivedSession: "myaccount_remember-me_long_lived_refresh_token",
  refreshTokenAvailable: "website_refresh_token_available",
}

const cookieOptions = {
  path: "/",
  domain: getApiRootDomain(),
}

export function useAuthSession() {
  const accessToken = ref<string | null>(null)
  const sessionExpiry = ref<Date | null>(null)
  const accessTokenExpiry = ref<Date | null>(null)

  const isAuthenticated = computed(() => {
    return Boolean(
      accessTokenExpiry.value &&
        new Date() <= new Date(accessTokenExpiry.value),
    )
  })

  function hasRefreshTokenCookie() {
    return !!useCookies().get(AuthCookie.refreshTokenAvailable)
  }

  function isLongLived() {
    return useCookies().get(AuthCookie.longLivedSession) === "true"
  }

  function clearSessionData() {
    const cookies = useCookies()
    accessToken.value = null
    sessionExpiry.value = null
    accessTokenExpiry.value = null

    cookies.remove(AuthCookie.longLivedSession, cookieOptions)
    cookies.remove(AuthCookie.refreshTokenAvailable, cookieOptions)
  }

  function setSessionData(
    data: AuthenticationResponse,
    isSessionExtended: boolean,
  ) {
    const cookies = useCookies()
    accessToken.value = data.accessToken
    accessTokenExpiry.value = new Date(data.accessTokenExpiry)
    sessionExpiry.value = new Date(data.sessionExpiry)

    if (isSessionExtended) {
      cookies.set(AuthCookie.longLivedSession, true, {
        ...cookieOptions,
        expires: sessionExpiry.value,
      })
    }
    cookies.set(AuthCookie.refreshTokenAvailable, true, {
      ...cookieOptions,
      expires: sessionExpiry.value,
    })
  }

  return {
    accessToken,
    isAuthenticated,
    hasRefreshTokenCookie,
    isLongLived,
    clearSessionData,
    setSessionData,
  }
}
