<script lang="ts" setup>
  import { authSession } from "@/authentication/authenticationService.ts"
  import { useLocalStorage } from "@vueuse/core"
  import { Button } from "@/components/ui/button"
  import {
    Dialog,
    DialogClose,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
  } from "@/components/ui/dialog"

  import soBucket from "@/assets/images/so-bucket.svg"

  const isOpen = useLocalStorage("onboardingDialogShown", true)
  const { isAuthenticated } = authSession
</script>
<template>
  <Dialog
    v-if="isAuthenticated"
    v-model:open="isOpen"
    data-testid="onboarding-dialog"
  >
    <DialogContent class="text-center">
      <DialogHeader class="mt-1 sm:mt-0">
        <img :src="soBucket" alt="" class="mb-3 h-[160px] sm:h-[190px]" />
        <DialogTitle>A fresh new look for your account!</DialogTitle>
      </DialogHeader>
      <div>
        <p>
          Experience your account in a fresh new look! We have completely
          restructured the account interface to help you get to were you need
          faster.
        </p>
        <p>Try it out and let us know what you think!</p>
      </div>
      <DialogFooter class="flex justify-center sm:justify-center">
        <DialogClose as-child>
          <Button>Got it</Button>
        </DialogClose>
      </DialogFooter>
    </DialogContent>
  </Dialog>
</template>
