/*
  Class facilitating awaiting promise completion
  separately from promise creation

  Example usage:

  const initialiser = new DeferredPromise()
  const player = null

  play () {
    await initialiser.promise()
    player.play()
  }
  async loadPlayer () {
    player = await downloadPlayer()
    initialiser.resolve()
  }

  play()
  loadPlayer()
*/

class DeferredPromise {
  public promise: Promise<void>
  public resolve!: () => void

  constructor() {
    this.promise = new Promise<void>((resolve) => {
      this.resolve = resolve
    })
  }
}
export default DeferredPromise
