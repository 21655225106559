<script setup lang="ts">
  import { getPasswordCriteriaList } from "@/lib/passwordUtils.ts"
  import { useVModel } from "@vueuse/core"
  import { ref } from "vue"
  import { TextInput } from "@/components/ui/inputs/text-input"

  const isPasswordShown = ref(false)

  const props = defineProps<{
    defaultValue?: string | number
    modelValue?: string | number
    label: string
    extraLabel?: string
    name: string
    autofocus?: boolean
    type?: string
    autocomplete?: string
    disabled?: boolean
    placeholder?: string
    showCriteria?: boolean
    required?: boolean
  }>()

  const emits =
    defineEmits<(e: "update:modelValue", payload: string | number) => void>()
  const modelValue = useVModel(props, "modelValue", emits, {
    passive: true,
    defaultValue: props.defaultValue,
  })

  const passwordCriteria = ref(getPasswordCriteriaList(""))

  const updatePasswordCriteria = (newPassword: string | number) => {
    passwordCriteria.value = getPasswordCriteriaList(newPassword.toString())
  }
</script>

<template>
  <TextInput
    :default-value="defaultValue"
    :model-value="modelValue"
    :label="label"
    :extra-label="extraLabel"
    :name="name"
    :autofocus="autofocus"
    :disabled="disabled"
    :placeholder="placeholder"
    :icon="isPasswordShown ? 'Eye' : 'ClosedEye'"
    :icon-aria-label="isPasswordShown ? 'Hide password' : 'Show password'"
    :icon-aria-expanded="isPasswordShown"
    icon-position="trailing"
    :type="isPasswordShown ? 'input' : 'password'"
    :autocomplete="autocomplete"
    :supporting-labels="showCriteria ? passwordCriteria : []"
    :required="required"
    keep-supporting-labels-on-error
    @update:model-value="updatePasswordCriteria"
    @icon-clicked="isPasswordShown = !isPasswordShown"
  />
</template>
