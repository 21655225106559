import { useGetAccountStates } from "@/api/account-state/account-state.ts"
import { useGetAccount } from "@/api/account/account.ts"
import { MeterAppointmentState } from "@/api/model"
import { authSession } from "@/authentication/authenticationService"
import {
  ENABLED_LINKS_ON_CLOSED_ACCOUNT,
  EXTENDED_NAVIGATION_ITEMS,
  NAV_LABELS,
  NAVIGATION_ITEMS,
  SIDEBAR_NAVIGATION_ITEMS,
} from "@/constants/navigation.ts"
import { aggregateQueries } from "@/lib/aggregateQueries"
import { useGetAccountId } from "@/lib/useGetAccountId.ts"
import { NavbarItem } from "@/types/Navbar"
import { computed, unref } from "vue"

type Config = {
  isTraditionalSmartEligible: boolean
  isWHDEligible: boolean
  isAccountClosed?: boolean
}

export function useNavigationItems() {
  const { isAuthenticated } = authSession

  const customerQuery = useGetAccountId({
    enabled: unref(isAuthenticated),
  })
  const accountQuery = useGetAccount(customerQuery.accountId)
  const accountStatesQuery = useGetAccountStates(customerQuery.accountId)

  const isAccountClosed = computed(() => accountQuery.data.value?.isClosed)
  const isWHDEligible = computed(() => !!accountQuery.data.value?.isWHDEligible)
  const isTraditionalSmartEligible = computed(
    () =>
      accountStatesQuery.data.value?.meterAppointment ===
      MeterAppointmentState.traditionalSmartEligible,
  )

  const sidebarNavigationItems = computed(() =>
    filterMainNavigationItems(SIDEBAR_NAVIGATION_ITEMS, {
      isWHDEligible: unref(isWHDEligible),
      isTraditionalSmartEligible: unref(isTraditionalSmartEligible),
      isAccountClosed: unref(isAccountClosed),
    }),
  )

  const navigationItems = computed(() =>
    filterMainNavigationItems(NAVIGATION_ITEMS, {
      isWHDEligible: unref(isWHDEligible),
      isTraditionalSmartEligible: unref(isTraditionalSmartEligible),
    }),
  )

  const extendedNavigationItems = computed(() =>
    filterMainNavigationItems(EXTENDED_NAVIGATION_ITEMS, {
      isWHDEligible: unref(isWHDEligible),
      isTraditionalSmartEligible: unref(isTraditionalSmartEligible),
    }),
  )

  const mobileNavigationItems = computed(() => {
    return unref(isAuthenticated)
      ? unref(sidebarNavigationItems)
      : unref(navigationItems)
  })

  return {
    sidebarNavigationItems,
    navigationItems,
    extendedNavigationItems,
    mobileNavigationItems,
    ...aggregateQueries([customerQuery, accountQuery, accountStatesQuery]),
  }
}

const filterMainNavigationItems = (
  items: NavbarItem[],
  { isTraditionalSmartEligible, isWHDEligible, isAccountClosed }: Config,
) => {
  const filteredItems = items.filter((item) => {
    switch (item.label) {
      case NAV_LABELS.smartMeterBooking:
        return isTraditionalSmartEligible
      case NAV_LABELS.warmHomeDiscount:
        return isWHDEligible
      default:
        return true
    }
  })

  return isAccountClosed
    ? filteredItems.map((item) => ({
        ...item,
        isDisabled: item.to
          ? !ENABLED_LINKS_ON_CLOSED_ACCOUNT.includes(item.to)
          : true,
      }))
    : filteredItems
}
