<script setup lang="ts">
  import { ExternalRoute } from "@/constants/externalRoute"
  import { useStepWizard } from "@/lib/useStepWizard"
  import { StepWizard } from "@/components/ui/step-wizard"

  import CotMoveInPersonalDetails from "./CotMoveInPersonalDetails.vue"

  const { modelValue } = useStepWizard()

  const onLeave = () => {
    window.location.assign(ExternalRoute.websiteHome)
  }
</script>

<template>
  <StepWizard
    :initial-step="modelValue"
    :steps="[{ route: CotMoveInPersonalDetails }]"
    title="Move In"
    @leave-page="onLeave"
  >
    <router-view />
  </StepWizard>
</template>
