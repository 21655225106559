<script lang="ts" setup>
  import { cn } from "@/lib/utils"
  import { SupportingLabel } from "@/types/InputComponentTypes.ts"
  import type { LabelProps } from "radix-vue"
  import type { HTMLAttributes } from "vue"
  import { Icon } from "@/components/ui/icons"
  import { iconsMap } from "@/components/ui/icons/iconsMap.ts"

  import { useFormField } from "./useFormField"

  const props = defineProps<
    LabelProps & {
      class?: HTMLAttributes["class"]
      icon?: keyof typeof iconsMap
      id?: string
      supportingLabel: SupportingLabel
    }
  >()

  const { error, formMessageId } = useFormField()
</script>

<template>
  <div :class="cn('flex w-full items-center justify-start gap-2', props.class)">
    <Icon
      v-if="supportingLabel.icon"
      :name="supportingLabel.icon"
      size="sm"
      data-testid="form-supporting-label-icon"
      class="shrink-0 text-secondary-500 dark:text-secondary-300"
      :aria-describedby="id || formMessageId"
    />
    <p
      :id="id || formMessageId"
      :aria-label="`${supportingLabel.label || ''} ${supportingLabel.boldLabel || ''}.`"
      :class="
        cn(
          'text-xs font-normal leading-5 text-secondary-500 dark:text-secondary-300',
          error && 'text-destructive',
          { 'pl-5': !supportingLabel.icon },
        )
      "
    >
      <span v-if="supportingLabel.label">{{ supportingLabel.label }} </span>
      <span v-if="supportingLabel.boldLabel" class="font-medium">{{
        supportingLabel.boldLabel
      }}</span>
      <slot />
    </p>
  </div>
</template>
