<script setup lang="ts">
  import { cn } from "@/lib/utils.ts"
  import { NavbarItem } from "@/types/Navbar.ts"
  import { ref } from "vue"
  import { Button } from "@/components/ui/button"

  const props = defineProps<{
    triggerLabel: string
    subItems?: NavbarItem[]
    itemClicked: () => void
  }>()

  const open = ref(false)
</script>

<template>
  <Button
    ref="triggerButton"
    variant="navSubItem"
    size="nav"
    icon="ChevronRight"
    :icon-class="
      cn(
        'ml-1 origin-[8px] pt-0.5 transition-transform duration-200 ease-in-out',
        open && 'translate-x-1 rotate-90 transform',
      )
    "
    icon-position="right"
    :class="
      cn(
        'group w-full justify-start rounded-md pl-4',
        open &&
          'bg-secondary-700 text-secondary-100 dark:bg-secondary-100 dark:text-secondary-700',
      )
    "
    :data-testid="`${triggerLabel}-nested-submenu-trigger`"
    @click="open = !open"
  >
    {{ props.triggerLabel }}
  </Button>
  <ul
    :class="cn(`!mt-1 hidden w-full space-y-1 text-left`, open && 'block')"
    @click="props.itemClicked"
  >
    <li v-for="({ to, label }, index) in props.subItems" :key="index">
      <Button
        variant="navSubItem"
        size="nav"
        as-child
        class="w-full justify-start rounded-md pl-8"
        :data-testid="`${label}-nested-submenu-nav-item`"
      >
        <a :href="to"> {{ label }}</a>
      </Button>
    </li>
  </ul>
</template>
