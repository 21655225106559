<script setup lang="ts">
  import { cn } from "@/lib/utils"
  import { Primitive, type PrimitiveProps } from "radix-vue"
  import { computed, HTMLAttributes } from "vue"
  import { Icon } from "@/components/ui/icons"
  import { IconType } from "@/components/ui/icons/iconsMap.ts"

  import {
    buttonIconVariants,
    buttonVariants,
    IconButtonPosition,
    type ButtonVariants,
  } from "."

  interface Props extends PrimitiveProps {
    variant?: ButtonVariants["variant"]
    size?: ButtonVariants["size"]
    class?: HTMLAttributes["class"]
    icon?: IconType
    iconClass?: string
    iconPosition?: IconButtonPosition
    loading?: boolean
    disabled?: boolean
  }

  const props = withDefaults(defineProps<Props>(), {
    as: "button",
    iconPosition: "right",
  })

  const iconSize = computed(() => {
    switch (props.size) {
      case "sm":
        return "sm"
      case "md":
        return "md"
      case "xl":
      case "lg":
        return "lg"
      default:
        return "md"
    }
  })

  const loadingIconSize = computed(() => {
    if (props.size === "sm") {
      return "sm"
    }
    return "md"
  })
</script>

<template>
  <Primitive
    :as="as"
    :as-child="asChild"
    :aria-disabled="disabled || loading"
    :disabled="disabled || loading"
    :class="
      cn(
        buttonVariants({
          variant,
          size,
          loadingPadding: loading ? size : undefined,
          iconRight: icon && iconPosition === 'right' ? size : undefined,
          iconLeft: icon && iconPosition === 'left' ? size : undefined,
        }),
        props.class,
      )
    "
  >
    <Icon
      v-if="loading"
      class="mr-2 animate-spin"
      :size="loadingIconSize"
      name="Spinner"
    />
    <Icon
      v-if="icon && iconPosition === 'left'"
      :class="cn('mr-2', buttonIconVariants({ variant }), iconClass)"
      :size="iconSize"
      :name="icon"
    ></Icon>
    <slot />
    <Icon
      v-if="icon && iconPosition === 'right'"
      :class="cn('ml-2', buttonIconVariants({ variant }), iconClass)"
      :size="iconSize"
      :name="icon"
    ></Icon>
  </Primitive>
</template>
