import { useGetCustomer } from "@/api/customer/customer.ts"
import { computed } from "vue"

export function useGetAccountId(
  { enabled }: { enabled: boolean } = { enabled: true },
) {
  const customer = useGetCustomer({ query: { enabled } })
  const accountId = computed(() => {
    return customer.data?.value?.accountIDLastVisited ?? 0
  })

  return {
    accountId,
    ...customer,
  }
}
