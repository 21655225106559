<script setup lang="ts">
  import { useStepWizard } from "@/lib/useStepWizard"
  import ProgressCard from "@/components/cards/progress-card/ProgressCard.vue"
  import { Button } from "@/components/ui/button"

  const { progress, goNext } = useStepWizard()
</script>

<template>
  <ProgressCard title="New Address" :progress="progress">
    <slot />

    <template #footer>
      <slot name="footer">
        <Button variant="tonal" size="md" @click="goNext">Next</Button>
      </slot>
    </template>
  </ProgressCard>
</template>
