<script lang="ts" setup>
  import { cn } from "@/lib/utils"
  import type { HTMLAttributes } from "vue"

  import { useFormField } from "./useFormField"

  const props = defineProps<{
    class?: HTMLAttributes["class"]
  }>()

  const { formDescriptionId } = useFormField()
</script>

<template>
  <p
    :id="formDescriptionId"
    :class="
      cn(
        'label-text text-secondary-500 peer-disabled:cursor-not-allowed peer-disabled:text-secondary-400',
        props.class,
      )
    "
  >
    <slot />
  </p>
</template>
