import { SupportingLabel } from "@/types/InputComponentTypes"

export const getInputAriaDescribedBy = (
  data: {
    supportingLabels?: SupportingLabel[]
    extraLabel?: string
    error?: string
  },
  formDescriptionId: string = "",
  formMessageId: string = "",
) => {
  const supportingLabelsIds =
    data.supportingLabels
      ?.map((_, index) => `${formMessageId}-${index}`)
      .join(" ") ?? ""

  const ids = [
    data.extraLabel ? formDescriptionId : null,
    data.error ? formMessageId : supportingLabelsIds,
  ]
    .filter(Boolean)
    .join(" ")

  return ids.length ? ids : undefined
}
