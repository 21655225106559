/**
 *
 * @returns Root domain of the API hostname
 * Used to share cookies across all subdomains, so that they are applied to passed to all backend hosnames
 */
export const getApiRootDomain = () => {
  if (!import.meta.env.PROD) {
    return location.hostname
  }
  const apiHostname = import.meta.env.VITE_API_GATEWAY_2_URL
  if (apiHostname) {
    const parts = apiHostname.split(".")
    return parts.slice(-2).join(".")
  }
}
