<script setup lang="ts">
  import { useVModel } from "@vueuse/core"
  import { Button } from "@/components/ui/button"
  import {
    Dialog,
    DialogClose,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
  } from "@/components/ui/dialog"

  const props = withDefaults(
    defineProps<{
      modelValue?: boolean
    }>(),
    {
      modelValue: false,
    },
  )

  const emits = defineEmits<{
    (e: "update:modelValue", payload: boolean): void
    (e: "leavePage"): void
  }>()
  const modelValue = useVModel(props, "modelValue", emits, {
    passive: true,
    defaultValue: false,
  })
</script>

<template>
  <Dialog v-model:open="modelValue">
    <DialogContent>
      <DialogHeader>
        <DialogTitle>Are you sure you want to leave?</DialogTitle>
      </DialogHeader>
      <div>
        Any progress you've made so far hasn't been saved. If you leave now,
        you'll lose any changes.
      </div>
      <DialogFooter>
        <DialogClose as-child>
          <Button variant="outline" @click="$emit('leavePage')">
            Leave page
          </Button>
        </DialogClose>
        <DialogClose as-child>
          <Button variant="primary">Stay on page</Button>
        </DialogClose>
      </DialogFooter>
    </DialogContent>
  </Dialog>
</template>
