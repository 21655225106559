import { cva, type VariantProps } from "class-variance-authority"

export { default as Button } from "./Button.vue"

const buttonConfig = {
  variants: {
    variant: {
      primary:
        "font-button focus-visible:ring-offset-2 bg-primary-300 hover:bg-primary-100 active:bg-primary-400 focus-visible:ring-offset-secondary-200 focus-visible:ring-primary-600 " +
        "dark:text-secondary-700 dark:focus-visible:ring-primary-500 dark:focus-visible:ring-offset-secondary-600 dark:disabled:bg-secondary-400 dark:disabled:text-secondary-500 ",
      tonal:
        "font-button focus-visible:ring-offset-2 bg-secondary-700 text-secondary-100 hover:text-primary-300 hover:bg-secondary-500 active:bg-primary-300 active:text-secondary-700 focus-visible:ring-offset-secondary-100 focus-visible:ring-primary-400 " +
        "dark:bg-secondary-100 dark:text-secondary-700 dark:hover:bg-primary-100 dark:active:bg-primary-300 dark:focus-visible:ring-offset-secondary-600 dark:focus-visible:ring-primary-300 dark:disabled:bg-secondary-400 dark:disabled:text-secondary-500 ",
      outline:
        "font-button focus-visible:ring-offset-2 border bg-transparent border-secondary-700 hover:bg-secondary-200 text-secondary-700 active:bg-secondary-700 active:text-secondary-100 focus-visible:ring-primary-300 focus-visible:border-transparent focus-visible:ring-offset-0 " +
        "dark:border-secondary-100 dark:text-secondary-100 dark:hover:bg-transparent dark:hover:text-primary-300 dark:hover:border-primary-300 dark:active:bg-secondary-100 dark:active:text-secondary-700 dark:active:border-secondary-100 dark:focus-visible:ring-primary-300 dark:focus-visible:border-transparent " +
        "dark:disabled:text-secondary-400 dark:disabled:border-secondary-400 dark:disabled:bg-transparent",
      text:
        "font-button focus-visible:ring-offset-2 text-secondary-700 hover:bg-secondary-200 active:bg-primary-300 active:text-secondary-700 focus-visible:ring-offset-0 focus-visible:ring-primary-300 " +
        "dark:active:bg-primary-300 dark:active:text-secondary-700 dark:hover:bg-secondary-500 dark:hover:text-secondary-100 dark:text-secondary-100 dark:disabled:bg-transparent dark:disabled:text-secondary-400 ",
      navSubItem:
        "py-1 px-4 hover:bg-secondary-300 hover:text-secondary-700 focus-visible:ring-primary-400 " +
        "dark:text-secondary-100 dark:hover:bg-secondary-600 dark:hover:text-secondary-100 dark:hover:ring-primary-300 ",
    },
    size: {
      sm: "h-7 px-4 py-2 text-[0.75rem] leading-3", // This text size is only meant to be used for Button component - https://designnotes.blog.gov.uk/2022/12/12/making-the-gov-uk-frontend-typography-scale-more-accessible/
      md: "h-9 px-6 py-2 text-xs",
      lg: "h-10 px-6 py-2 text-sm",
      xl: "h-11 px-8 py-3 text-base leading-6",
      nav: "px-4 py-1 text-base",
    },

    loadingPadding: {
      sm: "pl-3",
      md: "pl-4",
      lg: "pl-4",
      xl: "pl-6",
      nav: "",
    },
    iconRight: {
      sm: "pr-3 pl-4",
      md: "pr-4 pl-6",
      lg: "pr-4 pl-6",
      xl: "pr-6 pl-8",
      nav: "",
    },
    iconLeft: {
      sm: "pr-4 pl-3",
      md: "pr-6 pl-4",
      lg: "pr-6 pl-4",
      xl: "pr-8 pl-6",
      nav: "",
    },
  },
  defaultVariants: {
    variant: "primary",
    size: "md",
  },
} as const

const buttonIconConfig = {
  variants: {
    variant: {
      primary: "dark:stroke-secondary-700 ",
      tonal:
        "stroke-secondary-100 group-hover:stroke-primary-300 group-active:stroke-secondary-700 dark:stroke-secondary-700 dark:group-disabled:stroke-secondary-500 ",
      outline:
        "group-active:stroke-secondary-100 dark:stroke-secondary-100 dark:group-hover:stroke-primary-300 dark:group-active:stroke-secondary-70 dark:group-disabled:stroke-secondary-400 ",
      text: "stroke-secondary-700 group-active:stroke-secondary-700 dark:group-active:stroke-secondary-700 dark:group-hover:stroke-secondary-100 dark:stroke-secondary-100 dark:group-disabled:stroke-secondary-400 ",
      navSubItem:
        "group-hover:text-secondary-700 dark:text-secondary-100 dark:group-hover:text-secondary-100",
    },
  },
  defaultVariants: {
    variant: "primary",
  },
} as const

export const buttonVariants = cva(
  "group button inline-flex items-center justify-center whitespace-nowrap rounded-full transition-colors ring-offset-background focus-visible:outline-none focus-visible:ring-2 " +
    "disabled:pointer-events-none disabled:bg-secondary-300 disabled:text-secondary-400 disabled:border-none",
  buttonConfig,
)

export const buttonIconVariants = cva(
  "group-disabled:stroke-current",
  buttonIconConfig,
)

export type ButtonVariants = VariantProps<typeof buttonVariants>

export const buttonVariantKeys = Object.keys(
  buttonConfig.variants.variant,
) as Array<keyof typeof buttonConfig.variants.variant>

export const buttonSizeKeys = Object.keys(buttonConfig.variants.size) as Array<
  keyof typeof buttonConfig.variants.size
>

export type IconButtonPosition = "left" | "right"
