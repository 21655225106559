import { LocationQueryValue } from "vue-router"

import { getMyAccountUrl } from "./websiteUrls"

const isAbsoluteUrl = (url: string) => url.startsWith("https")

export function getRedirectUrl(
  redirectParam: LocationQueryValue | LocationQueryValue[],
) {
  const legacyMyAccountUrl = getMyAccountUrl()
  const redirectPath = Array.isArray(redirectParam)
    ? redirectParam[0]
    : redirectParam
  if (!redirectPath) return

  if (isAbsoluteUrl(redirectPath)) {
    return redirectPath
  } else {
    return `${legacyMyAccountUrl}${redirectPath}`
  }
}
