<script setup lang="ts">
  import { phoneRegex } from "@/lib/regexHelper"
  import { useCotMoveIn } from "@/lib/useCotMoveIn"
  import { toTypedSchema } from "@vee-validate/zod"
  import { useForm } from "vee-validate"
  import * as z from "zod"
  import Button from "@/components/ui/button/Button.vue"
  import Checkbox from "@/components/ui/inputs/checkbox/Checkbox.vue"
  import TextInput from "@/components/ui/inputs/text-input/TextInput.vue"
  import { StepWizardProgressCard } from "@/components/ui/step-wizard"

  const formSchema = toTypedSchema(
    z.object({
      email: z
        .string({
          required_error: "Please enter a valid email",
          invalid_type_error: "Please enter a valid email",
        })
        .email("Please enter a valid email"),
      firstName: z
        .string({
          required_error: "Please enter your first name",
          invalid_type_error: "Please enter your first name",
        })
        .min(1, "Please enter your first name"),
      lastName: z
        .string({
          required_error: "Please enter your last name",
          invalid_type_error: "Please enter your last name",
        })
        .min(1, "Please enter your last name"),
      phone: z
        .string({
          required_error: "Please enter a phone number",
          invalid_type_error: "Please enter a phone number",
        })
        .regex(phoneRegex, "Please enter a valid phone number"),
      // dateOfBirth: z
      //   .string({
      //     required_error: "Please enter a date of birth",
      //     invalid_type_error:
      //       "Please enter a valid date of birth in dd/mm/yyyy format",
      //   })
      //   .regex(
      //     dateStringSlashesRegex,
      //     "Please enter a valid date of birth in dd/mm/yyyy format",
      //   ),
      rewardsAndUpdates: z.boolean().default(false),
    }),
  )

  const { handleSubmit } = useForm({
    validationSchema: formSchema,
  })

  const onSubmit = handleSubmit(async (data) => {
    const { updateDetails } = useCotMoveIn()
    updateDetails(data)
    // go to next page to be implemented
  })
</script>

<template>
  <form
    class="flex w-full flex-wrap justify-center"
    novalidate
    @submit="onSubmit"
  >
    <StepWizardProgressCard title="Personal Details">
      <div
        class="mb-4 grid grid-cols-1 justify-between gap-x-6 gap-y-6 sm:mb-6 sm:grid-cols-2 sm:gap-y-4"
      >
        <TextInput name="firstName" type="text" label="First name" />
        <TextInput name="lastName" label="Last name" />
        <TextInput
          name="email"
          autofocus
          type="email"
          autocomplete="email"
          label="Email"
        />
        <TextInput name="phone" type="tel" autocomplete="tel" label="Phone" />
        <!-- DATE INPUT TO BE ADDED -->
      </div>
      <Checkbox
        name="rewardsAndUpdates"
        label="I'd like to hear about rewards and receive updates from So Energy"
      />
      <template #footer>
        <Button variant="tonal" type="submit" size="md"> Next </Button>
      </template>
    </StepWizardProgressCard>
  </form>
</template>
