<script setup lang="ts">
  import { cn } from "@/lib/utils"
  import { Separator as Divider } from "radix-vue"

  const props = defineProps<{ orientation: "vertical" | "horizontal" }>()
</script>

<template>
  <Divider
    v-bind="props"
    :class="
      cn(
        'shrink-0 bg-secondary-300 dark:bg-secondary-500',
        props.orientation === 'vertical' ? 'h-full w-[2px]' : 'h-[2px] w-full',
      )
    "
    data-testid="divider"
  />
</template>
