<script setup lang="ts">
  import { cn } from "@/lib/utils"
  import type { HTMLAttributes } from "vue"
  import { Divider } from "@/components/ui/divider"

  const props = defineProps<{
    class?: HTMLAttributes["class"]
  }>()
</script>

<template>
  <div :class="cn('flex flex-col', props.class)" data-testid="card-header">
    <slot />
    <Divider orientation="horizontal" class="mt-4" />
  </div>
</template>
