<script lang="ts" setup>
  import { useFormField } from "./useFormField"

  const { error, formItemId, formDescriptionId, formMessageId } = useFormField()
</script>

<template>
  <slot
    :error="error"
    :form-item-id="formItemId"
    :aria-invalid="!!error"
    :form-message-id="formMessageId"
    :form-description-id="formDescriptionId"
  />
</template>
