import { cva, type VariantProps } from "class-variance-authority"

export { default as IconButton } from "./IconButton.vue"

export const buttonIconConfig = {
  variants: {
    size: {
      sm: "p-1 rounded-sm",
      md: "p-2 rounded",
      responsive: "p-1 rounded-sm sm:p-2 sm:rounded",
    },
  },
  defaultVariants: {
    size: "responsive",
  },
} as const

export const iconButtonVariants = cva(
  "inline-flex items-center justify-center hover:bg-secondary-200 active:bg-primary-300 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-primary-400 disabled:pointer-events-none disabled:bg-secondary-300 disabled:text-secondary-400 transition-colors",
  buttonIconConfig,
)

export type IconButtonVariants = VariantProps<typeof iconButtonVariants>

export const iconButtonSizeKeys = Object.keys(
  buttonIconConfig.variants.size,
) as Array<keyof typeof buttonIconConfig.variants.size>
