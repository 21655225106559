import { ref } from "vue"

interface MoveInState {
  firstName?: string
  lastName?: string
  phone?: string
  email?: string
  dateOfBirth?: string
  rewardsAndUpdates?: boolean
}

const state = ref<MoveInState>({
  firstName: undefined,
  lastName: undefined,
  phone: undefined,
  email: undefined,
  dateOfBirth: undefined,
  rewardsAndUpdates: false,
})

export const useCotMoveIn = () => {
  const updateDetails = (data: MoveInState) => {
    state.value = { ...state.value, ...data }
  }

  return {
    state,
    updateDetails,
  }
}
