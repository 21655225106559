/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Portal API Gateway
 * OpenAPI spec version: 2.0.0
 */
import {
  useMutation,
  type MutationFunction,
  type UseMutationOptions,
  type UseMutationReturnType,
} from "@tanstack/vue-query"
import { unref, type MaybeRef } from "vue"

import { customInstance, type ErrorType } from ".././client"
import type {
  AuthenticationRequest,
  AuthenticationResponse,
  Error,
  RefreshTokenRequest,
  SendActivationEmailRequest,
  SendResetPasswordEmailRequest,
  UpdatePasswordRequest,
  UpdatePasswordResponse,
} from ".././model"
import { useMutationOptions } from ".././mutationOptions"

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1]

/**
 * @summary Authenticate a user
 */
export const authenticate = (
  authenticationRequest: MaybeRef<AuthenticationRequest>,
  options?: SecondParameter<typeof customInstance>,
) => {
  authenticationRequest = unref(authenticationRequest)

  return customInstance<AuthenticationResponse>(
    {
      url: `/api/v1/auth/login`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: authenticationRequest,
    },
    options,
  )
}

export const useAuthenticateMutationOptions = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authenticate>>,
    TError,
    { data: AuthenticationRequest },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof authenticate>>,
  TError,
  { data: AuthenticationRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof authenticate>>,
    { data: AuthenticationRequest }
  > = (props) => {
    const { data } = props ?? {}

    return authenticate(data, requestOptions)
  }

  const customOptions = useMutationOptions({ ...mutationOptions, mutationFn })

  return customOptions
}

export type AuthenticateMutationResult = NonNullable<
  Awaited<ReturnType<typeof authenticate>>
>
export type AuthenticateMutationBody = AuthenticationRequest
export type AuthenticateMutationError = ErrorType<Error>

/**
 * @summary Authenticate a user
 */
export const useAuthenticate = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authenticate>>,
    TError,
    { data: AuthenticationRequest },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationReturnType<
  Awaited<ReturnType<typeof authenticate>>,
  TError,
  { data: AuthenticationRequest },
  TContext
> => {
  const mutationOptions = useAuthenticateMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * @summary Update password for authenticated user. If no authenticated user, update by token only if provided in payload
 */
export const updatePassword = (
  updatePasswordRequest: MaybeRef<UpdatePasswordRequest>,
  options?: SecondParameter<typeof customInstance>,
) => {
  updatePasswordRequest = unref(updatePasswordRequest)

  return customInstance<UpdatePasswordResponse>(
    {
      url: `/api/v1/auth/password`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: updatePasswordRequest,
    },
    options,
  )
}

export const useUpdatePasswordMutationOptions = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updatePassword>>,
    TError,
    { data: UpdatePasswordRequest },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof updatePassword>>,
  TError,
  { data: UpdatePasswordRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updatePassword>>,
    { data: UpdatePasswordRequest }
  > = (props) => {
    const { data } = props ?? {}

    return updatePassword(data, requestOptions)
  }

  const customOptions = useMutationOptions({ ...mutationOptions, mutationFn })

  return customOptions
}

export type UpdatePasswordMutationResult = NonNullable<
  Awaited<ReturnType<typeof updatePassword>>
>
export type UpdatePasswordMutationBody = UpdatePasswordRequest
export type UpdatePasswordMutationError = ErrorType<Error>

/**
 * @summary Update password for authenticated user. If no authenticated user, update by token only if provided in payload
 */
export const useUpdatePassword = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updatePassword>>,
    TError,
    { data: UpdatePasswordRequest },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationReturnType<
  Awaited<ReturnType<typeof updatePassword>>,
  TError,
  { data: UpdatePasswordRequest },
  TContext
> => {
  const mutationOptions = useUpdatePasswordMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * @summary Logout a user
 */
export const logout = (options?: SecondParameter<typeof customInstance>) => {
  return customInstance<void>(
    { url: `/api/v1/auth/logout`, method: "POST" },
    options,
  )
}

export const useLogoutMutationOptions = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof logout>>,
    TError,
    void,
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof logout>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof logout>>,
    void
  > = () => {
    return logout(requestOptions)
  }

  const customOptions = useMutationOptions({ ...mutationOptions, mutationFn })

  return customOptions
}

export type LogoutMutationResult = NonNullable<
  Awaited<ReturnType<typeof logout>>
>

export type LogoutMutationError = ErrorType<Error>

/**
 * @summary Logout a user
 */
export const useLogout = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof logout>>,
    TError,
    void,
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationReturnType<
  Awaited<ReturnType<typeof logout>>,
  TError,
  void,
  TContext
> => {
  const mutationOptions = useLogoutMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * @summary Refresh token
 */
export const refreshToken = (
  refreshTokenRequest: MaybeRef<RefreshTokenRequest>,
  options?: SecondParameter<typeof customInstance>,
) => {
  refreshTokenRequest = unref(refreshTokenRequest)

  return customInstance<AuthenticationResponse>(
    {
      url: `/api/v1/auth/refresh`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: refreshTokenRequest,
    },
    options,
  )
}

export const useRefreshTokenMutationOptions = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof refreshToken>>,
    TError,
    { data: RefreshTokenRequest },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof refreshToken>>,
  TError,
  { data: RefreshTokenRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof refreshToken>>,
    { data: RefreshTokenRequest }
  > = (props) => {
    const { data } = props ?? {}

    return refreshToken(data, requestOptions)
  }

  const customOptions = useMutationOptions({ ...mutationOptions, mutationFn })

  return customOptions
}

export type RefreshTokenMutationResult = NonNullable<
  Awaited<ReturnType<typeof refreshToken>>
>
export type RefreshTokenMutationBody = RefreshTokenRequest
export type RefreshTokenMutationError = ErrorType<Error>

/**
 * @summary Refresh token
 */
export const useRefreshToken = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof refreshToken>>,
    TError,
    { data: RefreshTokenRequest },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationReturnType<
  Awaited<ReturnType<typeof refreshToken>>,
  TError,
  { data: RefreshTokenRequest },
  TContext
> => {
  const mutationOptions = useRefreshTokenMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * @summary Re-send activation email
 */
export const sendActivationEmail = (
  sendActivationEmailRequest: MaybeRef<SendActivationEmailRequest>,
  options?: SecondParameter<typeof customInstance>,
) => {
  sendActivationEmailRequest = unref(sendActivationEmailRequest)

  return customInstance<void>(
    {
      url: `/api/v1/auth/sendActivationEmail`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: sendActivationEmailRequest,
    },
    options,
  )
}

export const useSendActivationEmailMutationOptions = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof sendActivationEmail>>,
    TError,
    { data: SendActivationEmailRequest },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof sendActivationEmail>>,
  TError,
  { data: SendActivationEmailRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof sendActivationEmail>>,
    { data: SendActivationEmailRequest }
  > = (props) => {
    const { data } = props ?? {}

    return sendActivationEmail(data, requestOptions)
  }

  const customOptions = useMutationOptions({ ...mutationOptions, mutationFn })

  return customOptions
}

export type SendActivationEmailMutationResult = NonNullable<
  Awaited<ReturnType<typeof sendActivationEmail>>
>
export type SendActivationEmailMutationBody = SendActivationEmailRequest
export type SendActivationEmailMutationError = ErrorType<Error>

/**
 * @summary Re-send activation email
 */
export const useSendActivationEmail = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof sendActivationEmail>>,
    TError,
    { data: SendActivationEmailRequest },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationReturnType<
  Awaited<ReturnType<typeof sendActivationEmail>>,
  TError,
  { data: SendActivationEmailRequest },
  TContext
> => {
  const mutationOptions = useSendActivationEmailMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * @summary Send a reset password email
 */
export const sendPasswordResetEmail = (
  sendResetPasswordEmailRequest: MaybeRef<SendResetPasswordEmailRequest>,
  options?: SecondParameter<typeof customInstance>,
) => {
  sendResetPasswordEmailRequest = unref(sendResetPasswordEmailRequest)

  return customInstance<void>(
    {
      url: `/api/v1/auth/sendPasswordResetEmail`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: sendResetPasswordEmailRequest,
    },
    options,
  )
}

export const useSendPasswordResetEmailMutationOptions = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof sendPasswordResetEmail>>,
    TError,
    { data: SendResetPasswordEmailRequest },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof sendPasswordResetEmail>>,
  TError,
  { data: SendResetPasswordEmailRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof sendPasswordResetEmail>>,
    { data: SendResetPasswordEmailRequest }
  > = (props) => {
    const { data } = props ?? {}

    return sendPasswordResetEmail(data, requestOptions)
  }

  const customOptions = useMutationOptions({ ...mutationOptions, mutationFn })

  return customOptions
}

export type SendPasswordResetEmailMutationResult = NonNullable<
  Awaited<ReturnType<typeof sendPasswordResetEmail>>
>
export type SendPasswordResetEmailMutationBody = SendResetPasswordEmailRequest
export type SendPasswordResetEmailMutationError = ErrorType<Error>

/**
 * @summary Send a reset password email
 */
export const useSendPasswordResetEmail = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof sendPasswordResetEmail>>,
    TError,
    { data: SendResetPasswordEmailRequest },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationReturnType<
  Awaited<ReturnType<typeof sendPasswordResetEmail>>,
  TError,
  { data: SendResetPasswordEmailRequest },
  TContext
> => {
  const mutationOptions = useSendPasswordResetEmailMutationOptions(options)

  return useMutation(mutationOptions)
}
