<script lang="ts" setup>
  import { cn } from "@/lib/utils"
  import type { LabelProps } from "radix-vue"
  import type { HTMLAttributes } from "vue"
  import { Label } from "@/components/ui/inputs/label"

  import { useFormField } from "./useFormField"

  const props = defineProps<LabelProps & { class?: HTMLAttributes["class"] }>()

  const { formItemId } = useFormField()
</script>

<template>
  <Label
    :class="
      cn(
        'peer-disabled:cursor-not-allowed peer-disabled:text-secondary-400',
        props.class,
      )
    "
    :for="formItemId"
  >
    <slot />
  </Label>
</template>
