<script setup lang="ts">
  import { cn } from "@/lib/utils"
  import type { HTMLAttributes } from "vue"

  const props = defineProps<{
    class?: HTMLAttributes["class"]
  }>()
</script>

<template>
  <h3 :class="cn(props.class)" data-testid="card-title">
    <slot />
  </h3>
</template>
