import { clsx, type ClassValue } from "clsx"
import { twMerge } from "tailwind-merge"

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export function preventBodyScroll(preventScroll: boolean) {
  if (preventScroll) {
    document.body.classList.add("overflow-hidden")
  } else {
    document.body.classList.remove("overflow-hidden")
  }
}

export const safeJSONParse = (string: string): unknown => {
  try {
    return JSON.parse(string)
  } catch (err) {
    return {
      details: string,
    }
  }
}

export const formatCurrency = (amount: number) =>
  new Intl.NumberFormat("en-GB", {
    style: "currency",
    currency: "GBP",
  }).format(amount)
