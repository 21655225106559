import {
  authSession,
  ensureFreshTokenIfAuthenticated,
} from "@/authentication/authenticationService"
import { getMyAccountUrl } from "@/lib/websiteUrls"
import { RouteLocationNormalizedGeneric } from "vue-router"

export const beforeEachGuard = async function (
  to: RouteLocationNormalizedGeneric,
) {
  const isNexusHomeEnabled = import.meta.env.VITE_USE_NEXUS_HOMEPAGE === "true"
  const requiresAnon = to.matched.some((record) => record.meta.requiresAnon)
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth)

  if (requiresAnon && authSession.hasRefreshTokenCookie()) {
    if (isNexusHomeEnabled) return "/"

    const legacyMyAccountUrl = getMyAccountUrl()
    const queryString = window.location.search
    window.location.assign(`${legacyMyAccountUrl}${queryString}`)
    return false
  }

  if (requiresAuth) {
    await ensureFreshTokenIfAuthenticated()
    if (!authSession.isAuthenticated.value) {
      return "/login"
    }
  }
}
