<script setup lang="ts">
  import {
    authSession,
    useLogout,
  } from "@/authentication/authenticationService"
  import { ExternalRoute } from "@/constants/externalRoute.ts"
  import { Route } from "@/constants/route.ts"
  import { useNavigationItems } from "@/lib/useNavigationItems.ts"
  import { computed, ref } from "vue"
  import { useRoute, useRouter } from "vue-router"
  import LogoutErrorDialog from "@/components/LogoutErrorDialog.vue"
  import { Button } from "@/components/ui/button"

  import { Navbar } from "."

  const { navigationItems, extendedNavigationItems, mobileNavigationItems } =
    useNavigationItems()
  const route = useRoute()
  const router = useRouter()
  const { isAuthenticated } = authSession
  const isLogoutErrorShown = ref(false)
  const logout = useLogout({
    onSuccess: () => {
      router.push(Route.login)
    },
    onError: () => {
      isLogoutErrorShown.value = true
    },
  })

  const isMaintenanceRoute = computed(() => route.path === Route.maintenance)
</script>
<template>
  <Navbar
    :desktop-navigation-items="navigationItems"
    :tablet-navigation-items="extendedNavigationItems"
    :mobile-navigation-items="mobileNavigationItems"
  >
    <Button as-child variant="primary" data-testid="get-a-quote-button">
      <a :href="ExternalRoute.getAQuote">Get a Quote</a>
    </Button>
    <template v-if="!isMaintenanceRoute">
      <Button
        v-if="isAuthenticated"
        variant="outline"
        :loading="logout.isPending.value"
        data-testid="logout-button"
        @click="logout.mutate()"
      >
        Logout
      </Button>
      <Button v-else as-child variant="outline">
        <RouterLink :to="Route.login" data-testid="login-button">
          Account
        </RouterLink>
      </Button>
    </template>
    <Button v-else disabled variant="outline" data-testid="login-button">
      Account
    </Button>
    <LogoutErrorDialog
      v-if="isLogoutErrorShown"
      @dialog-closed="isLogoutErrorShown = false"
    />
  </Navbar>
</template>
