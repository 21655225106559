<script setup lang="ts">
  const props = defineProps<{
    to: string
  }>()
  const isHyperlink =
    props.to.startsWith("http") ||
    props.to.startsWith("tel:") ||
    props.to.startsWith("mailto:")
</script>

<template>
  <component
    :is="to === 'javascript:;' || isHyperlink ? 'a' : 'router-link'"
    :to="to"
    :href="to"
  >
    <slot></slot>
  </component>
</template>
