<script setup lang="ts">
  import { Button } from "@/components/ui/button"
  import Icon from "@/components/ui/icons/Icon.vue"

  const { title, hasBackButton } = defineProps<{
    title: string
    hasBackButton?: boolean
  }>()

  const emits = defineEmits(["back"])

  const onBackClick = () => {
    emits("back")
  }
</script>

<template>
  <div class="flex items-center gap-2 md:gap-3">
    <Button
      v-if="hasBackButton"
      variant="text"
      class="mt-1 active:bg-transparent dark:active:bg-transparent"
      @click="onBackClick"
    >
      <Icon size="lg" name="ArrowLeft" />
    </Button>
    <h1 class="text-secondary-700 dark:text-secondary-100">{{ title }}</h1>
  </div>
</template>
