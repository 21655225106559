import { useMediaQuery } from "@vueuse/core"

import config from "../../tailwind.config"

const screenBreakpoints = config.theme.screens
type BreakpointTypes = keyof typeof screenBreakpoints

export function useBreakpoint(breakpoint: BreakpointTypes) {
  return useMediaQuery(`(min-width: ${screenBreakpoints[breakpoint]})`)
}
