/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Portal API Gateway
 * OpenAPI spec version: 2.0.0
 */
import {
  useQuery,
  type QueryFunction,
  type QueryKey,
  type UseQueryOptions,
  type UseQueryReturnType,
} from "@tanstack/vue-query"
import { unref, type MaybeRef } from "vue"

import { customInstance, type ErrorType } from ".././client"
import type { AccountStates, Error } from ".././model"
import { useQueryOptions } from ".././queryOptions"

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1]

/**
 * @summary Retrieve account states
 */
export const getAccountStates = (
  accountId: MaybeRef<number>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  accountId = unref(accountId)

  return customInstance<AccountStates>(
    { url: `/api/v1/accounts/${accountId}/state`, method: "GET", signal },
    options,
  )
}

export const getGetAccountStatesQueryKey = (accountId: MaybeRef<number>) => {
  return ["api", "v1", "accounts", accountId, "state"] as const
}

export const useGetAccountStatesQueryOptions = <
  TData = Awaited<ReturnType<typeof getAccountStates>>,
  TError = ErrorType<Error>,
>(
  accountId: MaybeRef<number>,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getAccountStates>>,
        TError,
        TData
      >
    >
    request?: SecondParameter<typeof customInstance>
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = getGetAccountStatesQueryKey(accountId)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getAccountStates>>
  > = ({ signal }) => getAccountStates(accountId, requestOptions, signal)

  const customOptions = useQueryOptions(
    { ...queryOptions, queryKey, queryFn },
    { accountId },
  )

  return customOptions as UseQueryOptions<
    Awaited<ReturnType<typeof getAccountStates>>,
    TError,
    TData
  >
}

export type GetAccountStatesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAccountStates>>
>
export type GetAccountStatesQueryError = ErrorType<Error>

/**
 * @summary Retrieve account states
 */
export const useGetAccountStates = <
  TData = Awaited<ReturnType<typeof getAccountStates>>,
  TError = ErrorType<Error>,
>(
  accountId: MaybeRef<number>,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getAccountStates>>,
        TError,
        TData
      >
    >
    request?: SecondParameter<typeof customInstance>
  },
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetAccountStatesQueryOptions(accountId, options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = unref(queryOptions).queryKey as QueryKey

  return query
}
