import { InternalAxiosRequestConfig } from "axios"

import {
  authSession,
  ensureFreshTokenIfAuthenticated,
} from "./authenticationService"

const authenticateInterceptor = async (request: InternalAxiosRequestConfig) => {
  if (!request.url) {
    return request
  }

  const isAuthTokenRequired =
    !request.url.startsWith("/api/v1/auth") ||
    request.url.startsWith("/api/v1/auth/logout")
  if (isAuthTokenRequired) {
    await ensureFreshTokenIfAuthenticated()

    const { accessToken } = authSession
    if (accessToken.value && request.headers) {
      request.headers.Authorization = `Bearer ${accessToken.value}`
    }
  }

  return request
}

export default authenticateInterceptor
