<script setup lang="ts">
  import { cn } from "@/lib/utils"
  import type { HTMLAttributes } from "vue"

  const props = defineProps<{
    class?: HTMLAttributes["class"]
  }>()
</script>

<template>
  <div
    :class="cn('pb-4 pt-3 text-sm sm:text-base', props.class)"
    data-testid="card-content"
  >
    <slot />
  </div>
</template>
