import { canInitializeDatadog } from "@/plugins/datadog/datadogHelper.ts"
import { datadogRum } from "@datadog/browser-rum"

export default {
  sendEvent(eventName: string, metadata = {}) {
    if (canInitializeDatadog()) {
      datadogRum.addAction(eventName, metadata)
    }
  },
}
