import { SupportingLabel } from "@/types/InputComponentTypes.ts"
import * as z from "zod"
import { iconsMap } from "@/components/ui/icons/iconsMap.ts"

const specialCharacterRegex = /[\d\W_]/ // Numbers or symbols
const uppercaseRegex = /[A-Z]/ // Uppercase letter

export const passLengthValidationSchema = z
  .string()
  .min(9, "Password must be at least 9 characters long")

export const specialCharacterValidationSchema = z
  .string()
  .regex(
    specialCharacterRegex,
    "Password must contain at least one number or symbol",
  )

export const uppercaseValidationSchema = z
  .string()
  .regex(uppercaseRegex, "Password must contain at least one uppercase letter")

export const passwordValidationSchema = (requiredErrorMessage: string) =>
  z
    .string({ required_error: requiredErrorMessage })
    .and(passLengthValidationSchema)
    .and(specialCharacterValidationSchema)
    .and(uppercaseValidationSchema)

const weakPasswordRegex = /^[a-z]+$/ // Only lowercase letters
const goodPasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{9,}$/ // At least one uppercase, one lowercase, one number, and at least 9 characters
const strongPasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{12,}$/ // At least one uppercase, one lowercase, one number, one special char, and at least 12 characters

export function getPasswordStrength(password: string): {
  strength: string
  strengthIcon: keyof typeof iconsMap
} {
  if (strongPasswordRegex.test(password)) {
    return {
      strength: "Strong",
      strengthIcon: "Check",
    }
  }
  if (goodPasswordRegex.test(password)) {
    return {
      strength: "Good",
      strengthIcon: "Check",
    }
  }
  if (weakPasswordRegex.test(password) && password.length < 9) {
    return {
      strength: "Weak",
      strengthIcon: "x",
    }
  }
  return {
    strength: "Weak",
    strengthIcon: "x",
  }
}

export function getPasswordCriteriaIcon(
  password: string | number,
  criteria: z.ZodString,
): keyof typeof iconsMap {
  return criteria.safeParse(password).success ? "Check" : "x"
}

export function getPasswordCriteriaList(
  password: string = "",
): SupportingLabel[] {
  const { strengthIcon, strength } = getPasswordStrength(password)

  return [
    {
      label: "Password strength: ",
      boldLabel: strength,
      icon: strengthIcon,
    },
    {
      label: "Must contain at least 9 characters",
      icon: getPasswordCriteriaIcon(password, passLengthValidationSchema),
    },
    {
      label: "Contains a number or a symbol",
      icon: getPasswordCriteriaIcon(password, specialCharacterValidationSchema),
    },
    {
      label: "Contains at least one uppercase character",
      icon: getPasswordCriteriaIcon(password, uppercaseValidationSchema),
    },
  ]
}
