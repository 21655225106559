import { datadogConfig } from "@/constants/datadog"
import { safeJSONParse } from "@/lib/utils.ts"
import { RumEvent } from "@datadog/browser-rum"
import axios, { AxiosError } from "axios"

/**
 * Sanitizes a tag name for use with Datadog.
 * https://docs.datadoghq.com/getting_started/tagging/#define-tags
 *
 * @param {string} value - The tag name to sanitize.
 * @returns {string|undefined} The sanitized tag name.
 */
export const sanitizeTagName = (value: string): string | undefined =>
  value?.toLowerCase().replace(/[^a-z0-9_:./-]/g, "_")

export const environment = () => {
  if (__APP_VERCEL_BRANCH__ === "main") {
    return __APP_IS_STAGING__ ? "staging" : "production"
  }
  return sanitizeTagName(__APP_VERCEL_BRANCH__)
}

export const commonDatadogParams = {
  clientToken: import.meta.env.VITE_DATADOG_CLIENT_TOKEN,
  site: datadogConfig.site,
  version: __APP_VERSION__,
  env: environment(),
  service: datadogConfig.service,
  sessionSampleRate: 100,
}

export const isServerError = <ResponseType>(
  error: unknown,
): error is AxiosError<ResponseType> => {
  return axios.isAxiosError(error)
}

export const canInitializeDatadog = () => !!__APP_VERCEL_BRANCH__

const sensitiveFieldNames = [
  "password",
  "checkPassword",
  "bank_account_number",
  "accountNumber",
  "sortCode",
  "sort_code",
  "token",
  "cardNumber",
  "card_number",
  "expiry",
  "code",
  "cardHolderName",
  "securityCode",
  "first_name",
  "last_name",
  "full_name",
  "accountName",
  "firstName",
  "lastName",
  "forename",
  "surname",
  "careOf",
  "phoneNumber1",
  "phoneNumber2",
]

export function redactSensitiveFields<T>(data: T): T {
  if (Array.isArray(data)) {
    return data.map((item) => redactSensitiveFields(item)) as T
  }
  if (typeof data === "object" && data !== null) {
    const obfuscatedData: Record<string, unknown> = {}
    for (const fieldName in data) {
      const fieldValue = data[fieldName]
      if (Object.prototype.hasOwnProperty.call(data, fieldName)) {
        if (typeof fieldValue === "object") {
          obfuscatedData[fieldName] = redactSensitiveFields(fieldValue)
        } else if (sensitiveFieldNames.includes(fieldName)) {
          obfuscatedData[fieldName] = "*REDACTED*"
        } else {
          obfuscatedData[fieldName] = fieldValue
        }
      }
    }
    return obfuscatedData as T
  }
  return data
}

export const setRequestMetadata = (event: RumEvent, error: AxiosError) => {
  const responseData = error?.response?.data
  const requestData = error?.config?.data
    ? safeJSONParse(error?.config?.data)
    : null
  const responseCode = error?.response?.status
  const endpointURL = error?.config?.url

  event.context = event.context || {}
  if (responseCode) event.context.responseCode = responseCode
  if (endpointURL) event.context.endpointURL = endpointURL
  if (responseData) {
    event.context.responseData = redactSensitiveFields(responseData)
  }
  if (requestData) {
    event.context.requestData = redactSensitiveFields(requestData)
  }
}
