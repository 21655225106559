import { UseQueryReturnType } from "@tanstack/vue-query"
import { computed, ComputedRef } from "vue"

type ExtractErrorTypes<T extends { error: unknown }[]> = {
  [K in keyof T]: T[K] extends { error: infer U } ? U : never
}[number]

export type QueryReturnType = UseQueryReturnType<unknown, unknown>

export interface AggregatedQueryResult<T extends QueryReturnType[]>
  extends Pick<
    QueryReturnType,
    | "isError"
    | "isFetching"
    | "isLoading"
    | "isRefetching"
    | "isFetched"
    | "isSuccess"
  > {
  error: ExtractErrorTypes<T>
  isError: ComputedRef<boolean>
  isFetching: ComputedRef<boolean>
  isFetched: ComputedRef<boolean>
  isLoading: ComputedRef<boolean>
  isRefetching: ComputedRef<boolean>
  isSuccess: ComputedRef<boolean>
}

/**
 * Aggregates multiple TanStack queries to single set of computed values
 * to simplify query handling. It combines states like `isError` and `isSuccess`
 * and derives a unified `error`.
 *
 * @template T - An array of TanStack queries.
 * @param {T} queries - Array of TanStack query objects to aggregate.
 * @returns {AggregatedQueryProps<T>} An object containing aggregated computed properties.
 *
 * @example
 * ```typescript
 * import { useGetCustomer, useGetAccount } from "@/api";
 * import { aggregateQueries } from "@/utils/aggregateQueries";
 *
 * const customerQuery = useGetCustomer();
 * const accountQuery = useGetAccount();
 *
 * const {
 *   isError,
 *   isFetching,
 *   isFetched,
 *   isLoading,
 *   isRefetching,
 *   isSuccess,
 *   error
 * } = aggregateQueries([customerQuery, accountQuery]);
 *
 * if (isError.value) {
 *   console.error("An error occurred:", error.value);
 * }
 * ```
 */
export function aggregateQueries<T extends QueryReturnType[]>(
  queries: T,
): AggregatedQueryResult<T> {
  const isError = computed(() => queries.some((query) => query.isError.value))
  const isFetching = computed(() =>
    queries.some((query) => query.isFetching.value),
  )
  const isLoading = computed(() =>
    queries.some((query) => query.isLoading.value),
  )
  const isRefetching = computed(() =>
    queries.some((query) => query.isRefetching.value),
  )
  const isFetched = computed(() =>
    queries.some((query) => query.isFetched.value),
  )
  const isSuccess = computed(() =>
    queries.every((query) => query.isSuccess.value),
  )
  const error = computed(
    () => queries.find((query) => query.isError.value)?.error?.value,
  ) as ExtractErrorTypes<T>

  return {
    isError,
    isFetching,
    isFetched,
    isLoading,
    isRefetching,
    isSuccess,
    error,
  }
}
