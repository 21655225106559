import { AxiosError, AxiosRequestConfig } from "axios"

import { logout } from "./authenticationService"

const handleAuthErrorInterceptor = async (error: AxiosError) => {
  const originalRequest = error.config as AxiosRequestConfig
  const isAuthTokenRequired = !originalRequest.url?.startsWith("/api/v1/auth")
  if (
    error.response &&
    error?.response?.status === 403 &&
    isAuthTokenRequired
  ) {
    try {
      await logout()
    } finally {
      window.location.assign(`/myaccount/login`)
    }
  }

  return Promise.reject(error)
}

export default handleAuthErrorInterceptor
