import { Route } from "@/constants/route.ts"
import { Component } from "vue"
import { RouteRecordRaw } from "vue-router"

import LoginPage from "@/views/authentication/login/Login.vue"
import CotMoveInConfirmation from "@/views/cot-move-in/CotMoveInConfirmation.vue"
import CotMoveInContainer from "@/views/cot-move-in/CotMoveInContainer.vue"
import CotMoveInPersonalDetails from "@/views/cot-move-in/CotMoveInPersonalDetails.vue"

const routesDefinition = [
  {
    path: Route.home,
    components: {
      default: (): Promise<Component> => import("@/views/home/HomeView.vue"),
      sidebar: (): Promise<Component> => import("@/views/sidebar/Sidebar.vue"),
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: Route.readings,
    components: {
      default: (): Promise<Component> =>
        import("@/views/readings/Readings.vue"),
      sidebar: (): Promise<Component> => import("@/views/sidebar/Sidebar.vue"),
    },
  },
  {
    path: `${Route.readings}/submit/:id`,
    components: {
      default: (): Promise<Component> =>
        import("@/views/readings/submit/Reading.vue"),
      sidebar: (): Promise<Component> => import("@/views/sidebar/Sidebar.vue"),
    },
  },
  {
    path: `${Route.profile}`,
    components: {
      default: (): Promise<Component> => import("@/views/profile/Profile.vue"),
      sidebar: (): Promise<Component> => import("@/views/sidebar/Sidebar.vue"),
    },
  },
  {
    path: `${Route.help}`,
    components: {
      default: (): Promise<Component> => import("@/views/help/Help.vue"),
      sidebar: (): Promise<Component> => import("@/views/sidebar/Sidebar.vue"),
    },
  },
  {
    path: Route.login,
    component: LoginPage,
    meta: {
      requiresAnon: true,
    },
  },
  {
    path: Route.moveIn,
    component: CotMoveInContainer,
    children: [
      {
        path: Route.cotMoveInPersonalDetails,
        component: CotMoveInPersonalDetails,
      },
    ],
  },
  { path: Route.moveInComplete, component: CotMoveInConfirmation },
  {
    path: Route.maintenance,
    component: (): Promise<Component> =>
      import("@/views/maintenance/Maintenance.vue"),
    meta: {
      requiresAnon: false,
    },
  },
  {
    path: Route.passwordReset,
    component: (): Promise<Component> =>
      import("@/views/authentication/password-reset/PasswordReset.vue"),
    meta: {
      requiresAnon: true,
    },
  },
  {
    path: Route.passwordChange,
    component: (): Promise<Component> =>
      import("@/views/authentication/password-change/PasswordChange.vue"),
    meta: {
      requiresAnon: true,
    },
  },
  {
    path: Route.activateAccount,
    component: (): Promise<Component> =>
      import("@/views/authentication/activate-account/ActivateAccount.vue"),
    meta: {
      requiresAnon: true,
    },
  },
  {
    path: Route.setPassword,
    component: (): Promise<Component> =>
      import("@/views/authentication/set-password/SetPassword.vue"),
    meta: {
      requiresAnon: true,
    },
  },
  {
    path: Route.notFound,
    component: (): Promise<Component> => import("@/views/NotFound.vue"),
  },
] as const satisfies readonly RouteRecordRaw[]

export default routesDefinition
